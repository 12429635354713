#block-search, #block-search * {
    box-sizing: border-box;
}
.tl-block {
    position: relative;
}
#block-search {
    background: rgba(212, 221, 238, 0.85);
    bottom: 0;
    position: absolute!important;
    width: 100%;
    z-index: 9;
}
.tl-container {
    padding: 0 25px;
    max-width: 1450px;
    margin: 0 auto;
}